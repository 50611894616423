//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
export default {
  components: {
    BaseView: () => import("@/components/BaseView"),
    SchedulingSet: () => import("./schedulingSet"),
    XModal: () => import("@/components/XModal"),
  },
  props: {
    //团队信息
    selRow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      userSearch: null, // 成员
      loading: false,
      memberGroup: [], //成员列表
      generaId: null, //人员Index
      timeZonesOptions: [], //时区列表
      wageOptions: [], //时薪选项
    };
  },
  mounted() {
    this.getMember();
    this.getTimeZones();
    this.getWages();
  },
  methods: {
    imgChange,
    /**
     * 获取时区
     */
    getTimeZones() {
      this.$http
        .post("/Teams/GetSystemTimeZones.ashx", { searchText: null })
        .then((resp) => {
          if (resp.res == 0) {
            this.timeZonesOptions = resp.data;
          }
        });
    },
    /**
     * 获取时薪
     */
    getWages() {
      this.$http
        .post("/Teams/MemberWage/GetMemberWageType.ashx", {
          teamId: this.selRow.Id,
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.wageOptions = resp.data.Data;
          }
        });
    },
    /**
     * 批量设置
     */
    batchSettings() {
      this.$modal.show("batchSet");
    },
    // 左侧li点击事件
    liClick(obj) {
      if (obj) {
        this.generaId = obj.UserId;
      } else {
        this.generaId = null;
      }
    },
    getMember() {
      const data = {
        teamId: this.selRow.Id,
        searchText: this.userSearch,
      };
      this.$http
        .post("/Management/TeamManagement/MenberSelects.ashx", data)
        .then((res) => {
          if (res.res == 0) {
            this.memberGroup = res.data;
          }
        });
    },
  },
};
